










































































































import Vue from 'vue'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BFormText,
  BFormDatalist,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BCardCode from '@/vuexy/components/b-card-code'
import { codeHelper } from './code'

export default Vue.extend({
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormText,
    BButton,
    BFormDatalist,
  },
  directives: {
    Ripple,
  },
  setup() {
    const options = ['Devloper', 'Manager', 'CEO', 'CTO', 'Full Stack Devloper']

    return {
      options,
      codeHelper,
    }
  },
})
