


























import Vue from 'vue'
import { BRow, BCol } from 'bootstrap-vue'

import FormLayoutHorizontal from './FormLayoutHorizontal.vue'
import FormLayoutHorizontalIcon from './FormLayoutHorizontalIcon.vue'
import FormLayoutVertical from './FormLayoutVertical.vue'
import FormLayoutVerticalIcon from './FormLayoutVerticalIcon.vue'
import FormLayoutMultipleColumn from './FormLayoutMultipleColumn.vue'
import FormLayoutDatalistHelper from './FormLayoutDatalistHelper.vue'
import FormLayoutFeedbackHelper from './FormLayoutFeedbackHelper.vue'

export default Vue
  .extend({
    components: {
      BRow,
      BCol,

      FormLayoutHorizontal,
      FormLayoutHorizontalIcon,
      FormLayoutVertical,
      FormLayoutVerticalIcon,
      FormLayoutMultipleColumn,
      FormLayoutDatalistHelper,
      FormLayoutFeedbackHelper,
    },
  })
